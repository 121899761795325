import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0efca18c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "radio-group" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["radio", { 'active': (index === _ctx._value) }]),
        key: index,
        onClick: ($event: any) => (_ctx.handleClick(index))
      }, _toDisplayString(item.meta.title), 11, _hoisted_2))
    }), 128))
  ]))
}