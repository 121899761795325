
import { defineComponent } from "vue";
import useModel from "@/hooks/useModel";
export default defineComponent({
  name: "App",
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: Number
    }
  },
  setup(props, context) {
    
    const _value = useModel(props, 'value')
    const handleClick = (index: any) => {
      context.emit("on-change", index);
      _value.value = index
    };
    return {
      handleClick,
      _value
    };
  },
});
