
import { defineComponent, shallowRef } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
import type { RouteLocationNormalized } from 'vue-router'
import { findRoute } from '@/utils/payment/router'
import RadioGroup from './RadioGroup.vue'
import { btnPermissions } from '@/utils'

export default defineComponent({
  name: 'RouteTabs',
  props: {
    routeName: {
      type: [String, Symbol]
    }
  },
  setup(props, context) {
    console.log(context, 3333333)
    const subRoutes = props.routeName ?
      findRoute(props.routeName)?.children?.filter(
        (item) => item.meta?.showInTab) : []
    const activeKey = shallowRef<number>(0)

    const updateActiveKey = (to: RouteLocationNormalized) => {
      subRoutes?.some((item, index) => {
        if (item.name == to.name || item.name == to.meta?.activeTabName) {
          activeKey.value = index
          return true
        }
      })
    }

    const route = useRoute()
    updateActiveKey(route)
    onBeforeRouteUpdate(updateActiveKey)

    const router = useRouter()
    const tabClick = (tab: any) => {
      if (subRoutes) {
        router.push({name: subRoutes[tab].name})
      }
    }
    tabClick(activeKey.value)
    return {
      subRoutes,
      activeKey,
      tabClick
    }
  },
  components: {
    RadioGroup
  }
})
