
import { defineComponent } from 'vue'
import RadioTabs from "@/views/Checking/spotChecking/components/RadioTabs.vue"
export default defineComponent({
  name: 'App',
  props: {},
  setup() {
    console.log(2222)
    return {  }
  },
  components: {
    RadioTabs
  }
})
