import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08bef2f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "radio-group-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_radio_group = _resolveComponent("radio-group")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_radio_group, {
        data: _ctx.subRoutes,
        onOnChange: _ctx.tabClick,
        value: _ctx.activeKey,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event))
      }, null, 8, ["data", "onOnChange", "value"])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}